@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes scroll-horizontal {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); }
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
  
.animate-spin {
    animation: spin 1s linear infinite;
}

.animate-scroll-horizontal {
    animation: scroll-horizontal 35s linear infinite;
    display: flex;
    width: 200%;
}

.animate-scroll-horizontal:hover {
    animation-play-state: paused;
}

.animate-scroll-horizontal img {
    border: 2px solid #ce3d3d;
}

.animate-scroll-horizontal img {
    border: 2px solid #ce3d3d;
    box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease; 
}

.animate-scroll-horizontal:hover img {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3); /* Larger shadow on hover */
}

@media screen and (max-width: 768px) {
    .animate-scroll-horizontal {
        animation: scroll-horizontal 10s linear infinite;
    }
}

@keyframes carousel {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
.animate-carousel {
  animation: carousel 20s linear infinite;
}

.animate-carousel:hover {
  animation-play-state: paused;
}

@keyframes pulse {
  0%, 100% { opacity: 0.1; }
  50% { opacity: 0.3; }
}

@keyframes slideDown {
  from { max-height: 0; }
  to { max-height: 500px; }
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

#root {
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

::selection {
  background-color: rgba(255, 0, 0, 0.804);
  color: white;
}

::-moz-selection {
  background-color: rgba(255, 0, 0, 0.804);
  color: white;
}

select {
  background-color: white;
  color: red;
  border: 1px solid red;
}

select:focus {
  outline: none; 
  border-color: red;
  box-shadow: 0 0 5px red;
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: red;
}

.spinner {
  width: 44.8px;
  height: 44.8px;
  animation: spinner-y0fdc1 2s infinite ease;
  transform-style: preserve-3d;
}

.spinner > div {
  background-color: rgba(226,22,22,0.2);
  height: 100%;
  position: absolute;
  width: 100%;
  border: 2.2px solid #e21616;
}

.spinner div:nth-of-type(1) {
  transform: translateZ(-22.4px) rotateY(180deg);
}

.spinner div:nth-of-type(2) {
  transform: rotateY(-270deg) translateX(50%);
  transform-origin: top right;
}

.spinner div:nth-of-type(3) {
  transform: rotateY(270deg) translateX(-50%);
  transform-origin: center left;
}

.spinner div:nth-of-type(4) {
  transform: rotateX(90deg) translateY(-50%);
  transform-origin: top center;
}

.spinner div:nth-of-type(5) {
  transform: rotateX(-90deg) translateY(50%);
  transform-origin: bottom center;
}

.spinner div:nth-of-type(6) {
  transform: translateZ(22.4px);
}

@keyframes spinner-y0fdc1 {
  0% {
    transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
  }

  50% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
  }

  100% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
  }
}