@keyframes slideInUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .stagger-animation {
    opacity: 0;
    animation: slideInUp 0.6s ease-out forwards;
  }
  
  .stagger-1 { animation-delay: 0.3s; }
  .stagger-2 { animation-delay: 0.5s; }
  .stagger-3 { animation-delay: 0.7s; }
  .stagger-4 { animation-delay: 0.8s; }
  .stagger-5 { animation-delay: 0.9s; }